// todo 改
export const webinfo = {
    name: '朋来乐家政服务平台',
    spimpleName: '朋来乐',
    company: '朋来乐家政服务平台'
}

// todo 改
// export const imgapi = "https://xinmaijia.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
// export const adminurl = "https://sys.xmjservice.com/api/admin/";
// export const weburl = "https://sys.xmjservice.com/api/";
// export const uploadApi = "https://sys.xmjservice.com/api/upload_img";
// export const delOssApi = "https://sys.xmjservice.com/api/del_img/";

//  http://127.0.0.1:8888

// http://127.0.0.1:8888


// //
// export const imgapi = "https://penglaile.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
// export const adminurl = "http://localhost:8888/api/admin/";
// export const weburl = "http://localhost:8888/api/";
// export const uploadApi = "http://localhost:8888/api/upload_img";
// export const delOssApi = "http://localhost:8888/api/del_img/";


//todo 正式

export const imgapi = "https://penglaile.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
export const adminurl = "https://back.penglaile.com/api/admin/";
export const weburl = "https://back.penglaile.com/api/";
export const uploadApi = "https://back.penglaile.com/api/upload_img";
export const delOssApi = "https://back.penglaile.com/api/del_img/";

// export const imgapi = "https://penglaile.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
// export const adminurl = "http://127.0.0.1:8000/api/admin/";
// export const weburl = "http://127.0.0.1:8000/api/";
// export const uploadApi = "http://127.0.0.1:8000/api/upload_img";
// export const delOssApi = "http://127.0.0.1:8000/api/del_img/";

// todo 似乎是要也把这个放到前端去部署。。。。


// // 测试服API地址
// export const imgapi = "https://xinmaijia.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
// export const adminurl = "https://sys.poolaiwisdom.com/api/admin/";
// export const weburl = "https://sys.poolaiwisdom.com/api/";
// export const uploadApi = "https://sys.poolaiwisdom.com/api/upload_img";
// export const delOssApi = "https://sys.poolaiwisdom.com/api/del_img/";

// todo 腾讯地图Key 似乎没用到不用管



export const txMapKey = "BPQBZ-JOHWT-AQWXK-VTCIX-U6P67-PVFR5";