import goods from '@/utils/apis/goods.js'
import order from '@/utils/apis/order.js'
import shop from '@/utils/apis/shop.js'
import user from '@/utils/apis/user.js'
import wallet from '@/utils/apis/wallet.js'
import article from '@/utils/apis/article.js'
import staff from '@/utils/apis/staff.js'
import admin from '@/utils/apis/admin.js'

export default {
    ...goods,
    ...order,
    ...shop,
    ...user,
    ...wallet,
    ...staff,
    ...article,
    ...admin
}