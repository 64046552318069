import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
export default {
    // 用户列表
    async userList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "user/show", data)
    },
    // 用户状态
    async userStatus(data) {
        return await toPost(adminurl + "user/freeze_user", data)
    },
    // 重置密码
    async resetPwd(id) {
        return await toGet(adminurl + "user/reset_pwd/", id)
    },
    // 重置交易密码
    async resetPayPwd(id) {
        return await toGet(adminurl + "user/reset_pay_pwd/", id)
    },


    // 优惠券
    async ticketList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "property/ticket/show", data)
    },
    // 删除优惠券
    async deleteTicket(id) {
        return await toGet(adminurl + "property/ticket/delete/", id)
    },
    // 新增优惠券
    async addTicket(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "property/ticket/add", data)
    },
    // 修改优惠券
    async updateTicket(data) {
        return await toPost(adminurl + "property/ticket/update", data)
    },
    // 可用的优惠券
    async availableTicket() {
        return await toGet(adminurl + "property/ticket/sel/", store.getters.getShopId)
    },

    // 查询用户的优惠券
    async userTicketInfo(id) {
        return await toGet(weburl + "wallet/user_ticket_info/", id)
    },


    // 充值/ 资产变动
    async modifyUserResource(data) {
        return await toPost(adminurl + "user/modify_user_resource", data)
    },
    // 充值/ 资产变动
    async addUserTicket(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "user/add_user_ticket", data)
    },


    // 余额明细
    async userBalanceLog(data) {
        return await toPost(adminurl + "user/sel_user_balance_log", data)
    },
}