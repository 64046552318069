import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
export default {
    // 订单列表
    async orderList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(adminurl + "order/show", data)
    },
    // 订单列表
    async orderGoodsInfo(id) {
        return await toGet(adminurl + "order/order_goods_info/", id)
    },
    // 订单发货
    async orderTrack(data) {
        return await toGet(adminurl + "order/track_number", data)
    },


    // 预约单列表
    async serveOrderList(data) {
        data = {
            ...data,
            shop_id: store.getters.getShopId
        }
        return await toPost(weburl + "shop/server/show", data)
    },
    // 修改预约信息
    async modifyServeOrder(data) {
        return await toPost(weburl + "shop/server/modify", data)
    },
    // 取消预约单
    async cancelServe(data) {
        return await toPost(weburl + "shop/server/modify", data)
    },



















    // 物流公司列表
    async trackCode() {
        return await toGet(adminurl + "order/track_code")
    },

}